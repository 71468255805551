export const env = {
    API_URL: 'https://rivedegier.luloweb.fr/app_dev.php',
};

export function authToken() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.authdata) {
        return { 'X-Auth-Token': user.token };
    } else {
        return {};
    }
}